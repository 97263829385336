<template>
  <div>
    <top-nav></top-nav>
    <div
      class="flex flex-wrap md:flex-row min-h-screen mt-20 main-content bg-grey-300 pb-24 md:pb-5"
    >
      <div class="w-full">
        <registration-top-section :pathValue="value"></registration-top-section>
        <router-view @getValue="getValuefromChild"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationTopSection from "./components/registration-top-section/RegistrationTopSection.vue";
import { TopNav } from "@components";
export default {
  components: { TopNav, RegistrationTopSection },
  name: "MerchantRegistration",
  data() {
    return {
      value: 1,
    };
  },
  methods: {
    getValuefromChild(value) {
      this.value = value;
    },
  },
};
</script>
