<template>
  <div>
    <div class="pt-6 px-12">
      <div class="flex mb-6 items-center">
        <router-link to="/account/admin" class="btn btn-blue-light mr-4">
          <svg-icon name="ArrowLeft" class="w-6 h-3" />
        </router-link>
        <span class="font-base">Back to Profile Organization</span>
      </div>
      <div class="flex flex-wrap space-x-8 items-center mb-6">
        <h2 class="text-3xl font-bold text-blue-900">
          Merchant Application Form
        </h2>
        <div>
          <button
            class="font-extrabold text-blue"
            @click="showRequiredDoocumentsModal"
          >
            Required Documents
          </button>
          <modal
            classes="rounded-lg bg-white relative box-content"
            name="required-doccuments-modal"
            height="322"
            width="841"
          >
            <required-doccuments-form
              @close-modal="hideRequiredDoocumentsModal"
            />
          </modal>
        </div>
      </div>
    </div>
    <div class="border-b border-grey-700 border-opacity-40 w-full"></div>
    <div>
      <registration-tab
        :regTabs="regTabs"
        :pathValue="getPathValue"
      ></registration-tab>
    </div>
    <div class="border-b border-grey-700 border-opacity-40 w-full"></div>
  </div>
</template>
<script>
import RegistrationTab from "./registration-tab/RegistrationTab.vue";
import { SvgIcon } from "@components";
import RequiredDoccumentsForm from "../RequiredDoccumentsForm.vue";
export default {
  components: { SvgIcon, RegistrationTab, RequiredDoccumentsForm },
  name: "RegistrationTopSection",
  props: {
    pathValue: {
      type: Number,
    },
  },
  data() {
    return {
      regTabs: [
        {
          name: "Company Information",
          path: "/merchant-registration/company-information",
          value: 1,
        },
        {
          name: "Authorized Email",
          path: "/merchant-registration/authorized-email",
          value: 2,
        },
        {
          name: "Delivery Information",
          path: "/merchant-registration/delivery-information",
          value: 3,
        },
        {
          name: "Fees & Charges",
          path: "/merchant-registration/fees-charges",
          value: 4,
        },
        {
          name: "Supporting Documents",
          path: "/merchant-registration/supporting-documents",
          value: 5,
        },
      ],
    };
  },
  computed: {
    getPathValue() {
      return this.pathValue;
    },
  },
  methods: {
    showRequiredDoocumentsModal() {
      this.$modal.show("required-doccuments-modal");
    },
    hideRequiredDoocumentsModal() {
      this.$modal.hide("required-doccuments-modal");
    },
  },
};
</script>
