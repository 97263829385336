<template>
  <div class="px-12 flex flex-wrap items-center py-4">
    <div v-for="tab in getTabs" :key="tab.value">
      <router-link
        :to="tab.path"
        class="uppercase text-sm"
        :class="tab.value <= getPathValue ? 'text-orange' : 'text-blue-900'"
        >{{ tab.name }}</router-link
      >
      <span class="px-3" v-if="tab.value < 5">
        <svg-icon
          name="ChevronRight"
          class="w-6 h-3 text-blue-900 opacity-30"
        />
      </span>
    </div>
  </div>
</template>
<script>
import { SvgIcon } from "@components";
export default {
  name: "RegistrationTab",
  components: { SvgIcon },
  props: {
    regTabs: {
      type: Array,
    },
    pathValue: {
      type: Number,
    },
  },
  computed: {
    getTabs() {
      return this.regTabs;
    },
    getPathValue() {
      return this.pathValue;
    },
  },
};
</script>
