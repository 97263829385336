

















































import Vue from "vue";
import { SvgIcon } from "@/components";
export default Vue.extend({
  name: "RequiredDoccumentsForm",
  components: {
    SvgIcon,
  },
  data() {
    return {
      statusValue: "Active",
    };
  },
});
